import React, { Component, useState, useEffect, useContext } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import { connect } from 'react-redux'
import { Route, Link, withRouter, BrowserRouter as Router, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { getBusinessInfo, fetchBusinessInfo } from 'alpha-js'

const styles = theme => ({
  root: {
//    width: '100%',
//    display: 'flex',
//    justifyContent: 'center',
  },
})

const App = ({ params, theme, classes, history, business, showChat }) => {  
  

  window.goapp = {
    //...window.goapp,
    showChat: () => {
      showChat()
    },
  }  

  return (
    <div className={classes.root}>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  let urlParams = new URLSearchParams(ownProps.location.search)

  return {
    params: urlParams,
    user: state.user,
    visitor: state.visitor,
    business: getBusinessInfo(state, '0'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  let callbackUrl = window.location.href
  
  return {
  }
}

export default
  withRouter(
    withStyles(styles, { withTheme: true })(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(App)
    )
  )
  