import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { appReducer } from './data/reducer'
import { conversationMiddleware } from 'alpha-js'
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import App from './App'

import { withAppBase } from 'alpha-js'

import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'

import * as serviceWorker from './serviceWorker'

const renderApp = (appKey, props={}, container) => {
  if (!appKey) {
    alert("GOAPP: Invalid app key")
    return
  }
  
  const appArgs = {
    apiKey: appKey,
    apiUrl: process.env.REACT_APP_GOAPP_API_URL  
  }
  
  const store = createStore(
    appReducer,
    applyMiddleware(thunk.withExtraArgument(appArgs), 
      conversationMiddleware, logger)
    )
    
  const MyApp = withAppBase(App, {
    ...appArgs,
    ...props
    })
  
  if (!container) {
    container = document.createElement('div')
    document.body.appendChild(container)
  }

  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>  
          <MyApp {...props} />
        </Router>
      </ThemeProvider>
    </Provider>   
    , container);  
}

let container = document.getElementById('goapp-root')
if (container)
  renderApp("45115823594568", {},
    container)
else
  window.goapp = {
    init: (appKey, props, container) => {
      renderApp(appKey, props, container)
    }
  }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
